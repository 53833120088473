import React from 'react';
import {StatesButton} from 'wix-ui-tpa/StatesButton';
import classNames from 'classnames';
import addToCartButtonStylable from './AddToCartButton.st.css';
import * as productPageButtonsContainerStyles from '../ProductPageButtonsContainer.scss';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';

export interface AddToCartButtonProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  productInStock: boolean;
  handleAddToCart: any;
}

@withGlobalProps
@withTranslations('globals.texts')
export class AddToCartButton extends React.Component<AddToCartButtonProps> {
  public AddToCartButtonRef = React.createRef<StatesButton>();
  private a11yAnnouncer: Announcer;

  public componentDidMount(): void {
    this.a11yAnnouncer = new Announcer('product-page-announcer');
  }

  public componentWillUnmount() {
    this.a11yAnnouncer.cleanup();
  }

  public componentDidUpdate(prevProps: Readonly<AddToCartButtonProps>): void {
    const {
      shouldFocusAddToCartButton,
      addedToCartSuccessfully,
      shouldShowAddToCartSuccessAnimation,
      userInputs,
    } = this.props.globals;
    if (
      shouldFocusAddToCartButton !== false &&
      shouldFocusAddToCartButton !== prevProps.globals.shouldFocusAddToCartButton
    ) {
      this.AddToCartButtonRef.current.focus();
    }

    if (addedToCartSuccessfully !== prevProps.globals.addedToCartSuccessfully) {
      if (shouldShowAddToCartSuccessAnimation) {
        //tslint:disable-next-line no-floating-promises
        this.AddToCartButtonRef.current.onProgressReset();
      }

      if (userInputs.quantity[0] > 1) {
        this.a11yAnnouncer.announce(
          this.props.t('SR_ANNOUNCE_ADDED_TO_CART_PLURAL', {
            productsNumber: this.props.globals.userInputs.quantity[0],
          })
        );
      } else {
        this.a11yAnnouncer.announce(
          this.props.t('SR_ANNOUNCE_ADDED_TO_CART_SINGULAR', {productName: this.props.globals.product.name})
        );
      }
    }
  }

  public render() {
    const {
      t,
      productInStock,
      globals: {product, selectedVariant, shouldShowWishlistButton, isMobile},
    } = this.props;
    const {price} = selectedVariant || product;

    const disabled = !productInStock || price <= 0;
    let caption = t('ADD_TO_CART_BUTTON');

    if (disabled) {
      caption = price > 0 || !productInStock ? t('PRODUCT_OUT_OF_STOCK_BUTTON') : t('ADD_TO_CART_BUTTON_PRICE_ZERO');
    }

    const shouldShowWishlist = shouldShowWishlistButton && !isMobile;

    const buttonClasses = classNames({
      [addToCartButtonStylable.addToCartButton]: true,
      [productPageButtonsContainerStyles.primaryButton]: shouldShowWishlist,
    });

    return (
      <StatesButton
        ref={this.AddToCartButtonRef}
        text={caption}
        onClick={this.props.handleAddToCart}
        disabled={disabled}
        className={buttonClasses}
        fullWidth={!shouldShowWishlist}
        dataHook={'add-to-cart'}
      />
    );
  }
}
