import * as React from 'react';
import {ProvidedGlobalProps, withDirection, withGlobalProps} from '../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import classNames from 'classnames';
import s from './ProductPageButtonsContainer.scss';
import {Cell} from '../Layouts/Cell/Cell';
import {AddToCartButton} from './AddToCartButton/AddToCartButton';
import {Mode, WishlistButton} from './WishlistButton/WishlistButton';
import {isFunction} from 'util';
import {BuyNowButton} from './BuyNowButton/BuyNowButton';
import {inStock} from '../../services/products/producUtils';
import {ShowOnDesktopOnly} from '@wix/wixstores-client-common-components/dist/es/src/HOC/responsive/ShowOnDesktopOnly/ShowOnDesktopOnly';

export interface ProductPageButtonsContainerProps extends ProvidedGlobalProps, IProvidedTranslationProps {}

export enum DataHook {
  secondRow = 'buy-now-button-container',
}

@withGlobalProps
@withTranslations('globals.texts')
@withDirection
export class ProductPageButtonsContainer extends React.Component<ProductPageButtonsContainerProps> {
  private readonly handleAddToCart = async () => {
    const {handleAddToCart} = this.props.globals;

    if (isFunction(handleAddToCart)) {
      await handleAddToCart();
    }
  };

  private readonly shouldShowSecondRow = (): boolean => {
    const {
      globals: {shouldShowAddToCartButton, shouldShowBuyNowButton},
    } = this.props;
    return shouldShowAddToCartButton && shouldShowBuyNowButton;
  };

  public render() {
    const {
      globals: {selectedVariant, product, shouldShowWishlistButton, shouldShowAddToCartButton, shouldShowBuyNowButton},
    } = this.props;

    const buttonContainerClass = classNames(s.productOptionsContainer, {
      [s.addToCartButtonContainer]: shouldShowWishlistButton,
    });

    const secondRowClasses = classNames(s.productOptionsContainer, s.secondaryButton);
    const productInStock = inStock(product, selectedVariant);
    return (
      <>
        <Cell className={buttonContainerClass}>
          {shouldShowAddToCartButton && (
            <AddToCartButton productInStock={productInStock} handleAddToCart={this.handleAddToCart} />
          )}
          {shouldShowBuyNowButton && !shouldShowAddToCartButton && <BuyNowButton productInStock={productInStock} />}
          {shouldShowWishlistButton && (
            <ShowOnDesktopOnly>
              <WishlistButton mode={Mode.INLINE} />
            </ShowOnDesktopOnly>
          )}
        </Cell>
        {this.shouldShowSecondRow() && productInStock && (
          <Cell data-hook={DataHook.secondRow} className={secondRowClasses}>
            {<BuyNowButton productInStock={productInStock} />}
          </Cell>
        )}
      </>
    );
  }
}
